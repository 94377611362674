import "./App.css";
import SpeechToText from "./components/SpeechToText";

function App() {
  return (
    <div className="App">
      <SpeechToText />
    </div>
  );
}

export default App;
