import "./SpeechToText.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const SpeechToText = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transcriptResponse, setTranscriptResponse] = useState("");

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true });

  const stopListening = () => {
    SpeechRecognition.stopListening();
    if (transcript) {
      postData();
    }
  };

  // Define the POST request as a separate method
  const postData = async () => {
    setLoading(true);
    setError(null); // Reset error before making the request
    try {
      // Sending the POST request
      const result = await axios.post(
        "https://api-vitamin.a-book.dk/api/Assistant",
        {
          message: transcript,
        }
      );
      setResponse(result.data); // Update the response state with the returned data
    } catch (err) {
      setError(err); // Set the error state if something goes wrong
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  return (
    <div>
      <CardMedia
        sx={{ height: 140 }}
        image="https://images.unsplash.com/photo-1464226184884-fa280b87c399?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <br />
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          gutterBottom
        >
          <b>Hvad har du spist i dag?</b>
        </Typography>
        <Typography gutterBottom>
          {transcript ? (
            transcript
          ) : (
            <Typography color="textDisabled">
              "f.eks. havregryn, kaffe, sandwich"
            </Typography>
          )}
        </Typography>

        {transcript && response ? (
          <div>
            <br />
            <Divider />
            <List>
              {response?.map((item, index) => (
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </div>
        ) : null}
      </Container>
      <div className="flexbox">
        <Fab
          style={{ marginRight: "10px" }}
          color={listening ? "error" : "primary"}
          aria-label="record"
          onTouchStart={startListening}
          onMouseDown={startListening}
          onTouchEnd={stopListening}
          onMouseUp={stopListening}
          size="large"
        >
          {listening ? <MicOffIcon /> : <MicIcon />}
        </Fab>
        <Fab
          color="primary"
          aria-label="clear"
          onClick={resetTranscript}
          size="large"
        >
          <RestartAltIcon />
        </Fab>
      </div>
    </div>
  );
};

export default SpeechToText;
